function parseToken(encodedToken) {
  return JSON.parse(atob(encodedToken.split('.')[1]))
}

export default class LocalJWTManager {
  constructor(tokenName) {
    this.tokenName = tokenName
    this.encodedToken = localStorage.getItem(tokenName)
    if (this.encodedToken) {
      this.parsedToken = parseToken(this.encodedToken)
    }
  }

  login() {
    if (!this.parsedToken) {
      window.location.href = `/authorization/redirect`
    }

    this.encodedToken = localStorage.getItem(this.tokenName)
    this.parsedToken = parseToken(this.encodedToken)
  }

  isExpired() {
    if (!this.parsedToken) {
      return false
    }

    return new Date() > new Date(this.parsedToken['exp'] * 1000)
  }

  logout() {
    localStorage.removeItem(this.tokenName)
    this.encodedToken = null
    this.parsedToken = null
  }

  /**
   * The token's subject, which in practice, is the email address of the user
   */
  getUserName() {
    return this.parsedToken['sub']
  }
}
