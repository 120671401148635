import LocalJWTManager from './LocalJWTManager'

const jwtManager = new LocalJWTManager('tadpoleJWT')

function securedFetch(url, init = {}) {
  const jwtHeader = jwtManager.encodedToken
    ? { Authorization: `Bearer ${jwtManager.encodedToken}` }
    : null
  if (jwtHeader) {
    init.headers = { ...(init.headers || {}), ...jwtHeader }
  }
  return fetch(url, init)
}

export const checkResponse = (response) => {
  if (response.redirected && !response.ok) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response) => {
  return checkResponse(response).then((r) => r.json())
}

export const migrateService = (values) =>
  securedFetch(`/api/v1/webhook/tdp`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...values, user: jwtManager.getUserName() })
  })
