import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  gitRepo: yup
    .string()
    .matches(
      /^[\w-]+\/[\w-]+/,
      'You must include the organization (e.g. toasttab/toast-drones)'
    )
    .required('This field is required')
})
