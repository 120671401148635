import React from 'react'
import { ModalBody } from '@toasttab/buffet-pui-modal'

export const LilypadModalBody = ({ children }) => {
  return (
    <ModalBody>
      <div className='relative pt-1'>
        <div className='m-5 type-headline-4 text-center'>{children}</div>
      </div>
    </ModalBody>
  )
}
