import React from 'react'
import { Modal, ModalFooter } from '@toasttab/buffet-pui-modal'
import { CancelButton } from '@toasttab/buffet-pui-forms'
import { LilypadModalBody } from '../../LilypadModalBody/LilypadModalBody'

export const CompletedStatus = ({ status, serviceValues }) => {
  return (
    <Modal isOpen={true}>
      <LilypadModalBody>All Done!</LilypadModalBody>
      <ModalFooter>
        <CancelButton onClick={() => (window.location = '/')}>OK</CancelButton>
      </ModalFooter>
    </Modal>
  )
}
