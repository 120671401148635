import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'

import { Button } from '@toasttab/buffet-pui-buttons'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'

import { migrateService } from '../app/queries'
import { GitRepoField } from './MigrateInputFields'
import { useMutation } from 'react-query'
import { useService } from './ServiceProvider/ServiceProvider'
import { validationSchema } from './MigrateFormValidationSchema'
import { CompletedStatus } from './MigrateServiceStatus/CompletedStatus/CompletedStatus'
import { ErrorStatus } from './MigrateServiceStatus/ErrorStatus/ErrorStatus'

export const MigrateServiceForm = () => {
  const mutation = useMutation(migrateService)
  const { setServiceValues } = useService()

  const cancel = () => (window.location = '/')
  const appSubmit = async (values) => {
    setServiceValues(values)
    await mutation.mutateAsync(values).catch(() => {
      /* do nothing, error has already been displayed
            to the user from mutation.isError being true */
    })
  }

  if (mutation.data) {
    if (mutation.data.status !== 200) return <ErrorStatus />
    if (mutation.data.status === 200) return <CompletedStatus />
  }

  return (
    <div className='h-screen w-screen'>
      <Formik
        initialValues={{
          gitRepo: ''
        }}
        validationSchema={validationSchema}
        onSubmit={appSubmit}
      >
        {(props) => (
          <Form id='migrate-service' autoComplete='false'>
            <Modal
              parentSelector={() =>
                document.getElementsByClassName(
                  'single-spa-application:lilypad-migrate'
                )[0]
              }
              onRequestClose={cancel}
              isOpen
            >
              <ModalHeader>Add BoB Webhook</ModalHeader>
              <ModalBody>
                <div className='relative pt-1 pb-1'>
                  <div className='type-headline-5 italic'>
                    Add the BoB webhook to your git repo. Once added, every push
                    event will trigger a build in BoB.
                  </div>
                </div>
                <div className='flex flex-col space-y-4 text-default'>
                  <GitRepoField />
                </div>
                <div className='h-4'></div>
              </ModalBody>
              <ModalFooter>
                {mutation.isLoading && (
                  <>
                    <div className='flex-none'>
                      <MerryGoRound size='xxs' />
                    </div>
                  </>
                )}
                {mutation.isError && (
                  <div className='flex-grow sm:flex-none'>
                    <p>Lotus request failed.</p>
                  </div>
                )}
                <SubmitButton
                  form='migrate-service'
                  className='flex-grow sm:flex-none'
                />
                <Button
                  className='flex-grow sm:flex-none'
                  variant='link'
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  )
}
