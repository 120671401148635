import React from 'react'
import { TextInputField } from '@toasttab/buffet-pui-forms'
const BaseConfigField = (props) => <TextInputField {...props} />

export const GitRepoField = () => (
  <BaseConfigField
    name='gitRepo'
    label='GitHub Repo Name'
    placeholder='toasttab/drones'
  />
)
