import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { MigrateServiceForm } from '../components/MigrateServiceForm'
import { ServiceProvider } from '../components/ServiceProvider/ServiceProvider'

export const App = () => {
  const queryClient = new QueryClient()

  return (
    <ServiceProvider>
      <QueryClientProvider client={queryClient}>
        <MigrateServiceForm />
      </QueryClientProvider>
    </ServiceProvider>
  )
}
